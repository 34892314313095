<template>
  <div class="container my-24 my-lg-40">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-8 col-xl-6">
        <div class="row mb-24">
          <header class="col">
            <h2>Пожалуйста, заполните информацию о себе</h2>
          </header>
        </div>

        <v-card class="py-32 px-16 p-xl-32">
          <div
            v-for="(input, i) in inputs"
            :key="i"
            class="row"
          >
            <form class="col">
              <v-field
                :error="!!input.error"
              >
                <label>{{ input.label }}</label>
                <v-input
                  v-model="input.value"
                  :required="input.required"
                  :placeholder="input.placeholder"
                ></v-input>
                <span class="v-field__help">{{ input.help }}</span>
                <span class="v-field__error">{{ input.error }}</span>
              </v-field>
            </form>
          </div>

          <div class="row mt-32">
            <div class="col">
              <v-button
                :disabled="isClickable"
                @click="checkFields"
              >
                Отправить
              </v-button>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import VCard from '../../../components/base/VCard/VCard'
import { VField, VInput } from '../../../components/base/VField'
import VButton from '../../../components/base/VButton/VButton'

export default {
  name: 'SInputs',

  components: {
    VField,
    VButton,
    VInput,
    VCard
  },

  data () {
    return {
      inputs: [
        {
          value: '',
          label: 'Фамилия',
          placeholder: 'Введите вашу фамилию',
          required: true,
          help: 'Какая-то подсказка'
        },
        {
          value: '',
          label: 'Имя',
          placeholder: 'Введите ваше имя',
          required: true
        },
        {
          value: '',
          label: 'Отчество',
          placeholder: 'Введите ваше отчество'
        },
        {
          value: '',
          label: 'Очень длинный Label: Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aliquam cumque dicta dolor eos est ex fugiat fugit maxime nobis, perferendis perspiciatis possimus ullam vel velit veniam vitae. Nihil, ullam.',
          placeholder: 'Очень длинный placeholder: Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aliquam cumque dicta dolor eos est ex fugiat fugit maxime nobis, perferendis perspiciatis possimus ullam vel velit veniam vitae. Nihil, ullam.',
          help: 'Очень длинная подсказка: Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aliquam cumque dicta dolor eos est ex fugiat fugit maxime nobis, perferendis perspiciatis possimus ullam vel velit veniam vitae. Nihil, ullam.'
        }
      ]
    }
  },

  computed: {
    isClickable () {
      return !this.inputs.every(input => input.required ? !!input.value : true)
    }
  },

  methods: {
    checkFields () {
      this.inputs = this.inputs.map((input, i) => {
        if (input.required) {
          input.error = input.value.length < 3
            ? 'Поле должно содержать не менее 3х букв'
            : ''
        }

        return input
      })
    }
  }
}
</script>
